import type { HeroSection } from "@/modules/corporate/types/sections"
import type { FunctionComponent } from "react"

import cn from "classnames"
import Image from "next/image"

import { Button, BUTTON_VARIANT } from "@jobteaser/ui-kit/components/Button"
import { Layout } from "@jobteaser/ui-kit/components/Layout/Layout"
import { Text, TEXT_ALIGNS, TEXT_TYPES, TEXT_WEIGHTS } from "@jobteaser/ui-kit/components/Text/Text"

import { InternalLink } from "@/modules/corporate/components/InternalLink"
import { URL_PATHNAME } from "@/modules/corporate/constants"
import teasyFullLongTriangle from "@/modules/corporate/images/teasy-full-long-triangle.svg"
import teasyFullLongTriangleReverse from "@/modules/corporate/images/teasy-full-long-triangle-reverse.svg"
import teasyFullParallel from "@/modules/corporate/images/teasy-full-parallel.svg"
import teasyFullTriangle from "@/modules/corporate/images/teasy-full-triangle.svg"
import teasyTriangle from "@/modules/corporate/images/teasy-outline-triangle.svg"
import { useCorporateTrackingClick } from "@/modules/corporate/tracking/useCorporateTrackingClick"
import { RichText } from "@/modules/kontent/components/RichText"
import { useLocale } from "@/modules/locales/useLocale"
import { getAssetImportUrl } from "@/modules/staticAssets/getAssetImportUrl"

import styles from "./Hero.module.css"

export type HeroPropsType = {
  section: HeroSection
  isRootPage: boolean
  pageTitle: string
}

export const Hero: FunctionComponent<HeroPropsType> = ({ section, isRootPage, pageTitle }) => {
  const locale = useLocale()
  const trackClickEvent = useCorporateTrackingClick()

  const [firstWord, secondWord] = section.highlightedWords.split(" ")
  const teasyTriangleSecondaryUrl = `${getAssetImportUrl(teasyFullLongTriangle)}`
  const theme = section.theme[0] || ""

  let teasyTriangleUrl
  if (theme === "dark" || theme === "light") {
    teasyTriangleUrl = `${getAssetImportUrl(teasyTriangle)}`
  } else if (theme.includes("essentiel")) {
    teasyTriangleUrl = `${getAssetImportUrl(teasyFullTriangle)}`
  } else if (theme.includes("medias")) {
    teasyTriangleUrl = `${getAssetImportUrl(teasyFullParallel)}`
  } else if (theme.includes("vcf") || theme.includes("shortlist")) {
    teasyTriangleUrl = `${getAssetImportUrl(teasyFullLongTriangle)}`
  } else if (theme.includes("sponsoring")) {
    teasyTriangleUrl = `${getAssetImportUrl(teasyFullLongTriangleReverse)}`
  }

  return (
    <div className={styles.main} data-theme={theme.toLowerCase()}>
      <Layout className={styles.layout}>
        <Layout.Column className={styles.layoutColum}>
          <div
            className={cn(
              styles.gridWrapper,
              { [styles["gridWrapper--withNoImage"]]: !section.mainImage.length },
              { [styles["gridWrapper--withTwoImage"]]: !!section.secondaryImage.length },
              { [styles["gridWrapper--withTwoWorld"]]: !!secondWord }
            )}
            data-highlighted-word-one={firstWord}
            data-highlighted-word-two={secondWord}
          >
            <div
              className={styles.layoutTextBlock}
              style={{ "--backgroundImage": `url(${teasyTriangleUrl})` } as React.CSSProperties}
            >
              {!isRootPage && !!section.mainImage.length && (
                <Text
                  className={styles.pageTitle}
                  type={TEXT_TYPES.SUBHEAD_SMALL}
                  weight={TEXT_WEIGHTS.EXTRA_BOLD}
                  uppercase
                  resetSpacing
                >
                  {pageTitle}
                </Text>
              )}
              <RichText
                value={section.title}
                wrapperProps={{
                  align: !section.mainImage.length ? TEXT_ALIGNS.CENTER : TEXT_ALIGNS.LEFT,
                  className: styles.title,
                  resetSpacing: true,
                  tag: "div",
                }}
              />
              {section.description && (
                <RichText
                  value={section.description}
                  wrapperProps={{
                    className: styles.subtitle,
                    resetSpacing: true,
                    tag: "div",
                  }}
                />
              )}
              {!!section.ctaLink.length && (
                <Button
                  variant={BUTTON_VARIANT.NAKED}
                  className={styles.button}
                  component={InternalLink}
                  page={section.ctaLink[0]}
                  customTitle={section.ctaTitle || section.ctaLink[0].title}
                  onClick={() =>
                    trackClickEvent("corporate_cta_hero", {
                      clicked_url: `/${locale}/${URL_PATHNAME}/${section.ctaLink[0].slug}`,
                      visual_context: section.ctaTitle || section.ctaLink[0].title,
                    })
                  }
                />
              )}
            </div>
            {(!!section.mainImage.length || !!section.secondaryImage.length) && (
              <div className={styles.imagesWrapper}>
                {!!section.mainImage.length && (
                  <figure
                    className={cn(styles.figureImagePrimary, {
                      [styles["figureImagePrimary--withSecondary"]]: section.secondaryImage.length,
                    })}
                    style={
                      {
                        "--backgroundImage": `url(${teasyTriangleUrl})`,
                        "--height": `${section.mainImage[0].height}px`,
                      } as React.CSSProperties
                    }
                  >
                    <Image
                      src={section.mainImage[0].url}
                      alt={section.mainImage[0].description || ""}
                      width={section.mainImage[0].width || undefined}
                      height={section.mainImage[0].height || undefined}
                      className={cn(styles.imagePrimary, {
                        [styles["imagePrimary--withSecondary"]]: section.secondaryImage.length,
                      })}
                      style={{ "--widthImage": `${section.mainImage[0].width}px` } as React.CSSProperties}
                      sizes="54vw"
                      priority
                    />
                  </figure>
                )}
                {!!section.secondaryImage.length && (
                  <figure
                    className={styles.figureImageSecondary}
                    style={{ "--backgroundImage": `url(${teasyTriangleSecondaryUrl})` } as React.CSSProperties}
                  >
                    <Image
                      src={section.secondaryImage[0].url}
                      alt={section.secondaryImage[0].description || ""}
                      width={section.secondaryImage[0].width || undefined}
                      height={section.secondaryImage[0].height || undefined}
                      className={styles.imageSecondary}
                      sizes="52vw"
                      priority
                    />
                  </figure>
                )}
              </div>
            )}
          </div>
        </Layout.Column>
      </Layout>
    </div>
  )
}
